import config from "./config.json";
const bool = false;
const isLocal = bool;
const PROTOCOL = isLocal ? "http://" : "https://";
const WS_PROTOCOL = isLocal ? "ws://" : "wss://";
const HOST = isLocal ? "127.0.0.1:1993" : config.HOST;
const WS_ENDPOINT = "/subscriptions";
const GOOGLE_API_KEY = config.GOOGLE_API_KEY;
const STRIPE_ENDPOINT = "https://api.stripe.com/v1/tokens";
const STRIPE_DASHBORD = "https://dashboard.stripe.com/login";
const STRIPE_SECRET_KEY = "sk_test_X5KuJ3uuq3Ktjg3aQOjoLRdC00cPWoI5ts";
const STRIPE_PUPLIC_KEY = "pk_test_aZ6WqOrDWOMN8H5d3gjy0nyn00FAFiuUSj";
const BASE_URL = `${PROTOCOL}${HOST}`;
const PHOTO_URL = `${PROTOCOL}${HOST}`;
const WS_URL = `${WS_PROTOCOL}${HOST}${WS_ENDPOINT}`;
const DEFAULT_LANGAGE = "fr";
const APP_ID = config.APP_ID;
const DASH_URL = config.DASH_URL;
const SUPER_ADMIN_API = config.SUPER_ADMIN_API;
const APP_THEME = config.APP_THEME;
const App = {
  name: "LynkBooster",
  logo: "https://admin.flowerly.fr/img/logo-web.png",
};
const LANGUAGES = [
  {
    flag: "fr",
    langage: "Français",
    lang: "fr",
  },
  {
    flag: "US",
    langage: "English",
    lang: "en",
  },
];
//
const DeliveryApiTypes = [
  "STUART",
  "YPER",
  "CUSTOM",
  "PLATFORM_DRIVERS",
  "MY_DRIVERS",
  "OTHERS",
];
const PAYMENT_APIS = { stripe: "stripe", paypal: "paypal", paymee: "paymee" };
const UNIQUE_ADMIN_STORE_SHOW_MENU = ["discount", "delivery"];
const UNIQUE_ADMIN_STORE_HIDDEN_MENU = ["pros"];
const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const NAME_REGEX = /^[a-zA-Z0-9 '-]{2,50}$/;
const REAL_NAME_REGEX = /^[a-zA-Z ]{2,50}$/;
const REGEX_MOBILE = /*/^(?:\+216)[0-9]{8}$/; */ /^(?:\+33)[0-9]{9,10}$/;
const MOBILE_REGEX_INCLUSIVE = /^(:?(?:\+|00)33|0)[1-9]{1}[0-9]{8}$/;
const REGEX_MOBILE_NO_PREFIX = /*/^(?:\+216)[0-9]{8}$/; */ /^[0-9]{9}$/;
const PREPARATION_TIME = /^[0-9]{2}$/;

const VAT_REGEX = /^(FR)[0-9]{11}$/;
const SIRET_REGEX = /^[0-9]{14}$/;
const SIREN_REGEX = /^[0-9]{9}$/;

const RIB_REGEX = /^[0-9]{11}$/;
const limits = [10, 20, 40, 60, 80, 100];
const FRENCH_DATE_FORMAT = "DD/MM/YYYY";
const FRENCH_DATE_TIME_FORMAT = `${FRENCH_DATE_FORMAT} [à] HH:mm`;
const userTypes = {
  DRIVER: "DRIVER",
  CUSTOMER: "CUSTOMER",
  MANAGER: "MANAGER",
  ADMIN: "ADMIN",
  SUPER_ADMIN: "SUPER_ADMIN",
};

const INACTIVE = "INACTIVE";
const ACTIVE = "ACTIVE";
const IN_PROGRESS = "IN_PROGRESS";

const actorStatusSelect = [
  {
    label: "Tous",
    value: "none",
  },
  {
    label: "Actif",
    value: ACTIVE,
  },
  {
    label: "Inactif",
    value: INACTIVE,
  },
];

const storeCategoriesSelect = [
  {
    label: "Tous",
    value: "none",
  },
  {
    label: "Restaurant",
    value: "BUSINESS",
  },
  {
    label: "Artisan",
    value: "AT_HOME",
  },
];

const stripeFilterSelect = [
  {
    label: "Tous",
    value: "none",
  },
  {
    label: "Avec",
    value: "YES",
  },
  {
    label: "Sans",
    value: "NO",
  },
];

export {
  BASE_URL,
  WS_URL,
  PHOTO_URL,
  GOOGLE_API_KEY,
  STRIPE_ENDPOINT,
  STRIPE_SECRET_KEY,
  STRIPE_PUPLIC_KEY,
  App,
  DEFAULT_LANGAGE,
  LANGUAGES,
  STRIPE_DASHBORD,
  DeliveryApiTypes,
  PAYMENT_APIS,
  UNIQUE_ADMIN_STORE_SHOW_MENU,
  UNIQUE_ADMIN_STORE_HIDDEN_MENU,
  APP_ID,
  DASH_URL,
  SUPER_ADMIN_API,
  APP_THEME,
  EMAIL_REGEX,
  NAME_REGEX,
  REAL_NAME_REGEX,
  REGEX_MOBILE,
  MOBILE_REGEX_INCLUSIVE,
  REGEX_MOBILE_NO_PREFIX,
  PREPARATION_TIME,
  VAT_REGEX,
  SIRET_REGEX,
  SIREN_REGEX,
  RIB_REGEX,
  limits,
  FRENCH_DATE_TIME_FORMAT,
  userTypes,
  INACTIVE,
  ACTIVE,
  IN_PROGRESS,
  actorStatusSelect,
  storeCategoriesSelect,
  stripeFilterSelect,
};
