import gql from 'graphql-tag';
import {
  ADD_COMMERCIAL_MUTATION,
  UPDATE_COMMERCIAL_MUTATION,
  GET_COMMERCIALS_MUTATION,
  GET_COMMERCIAL_QUERRY,
  BLOCK_USER,
} from 'graphql/tags';
import { useMutation, useQuery } from 'react-apollo';

const FragmentCommercials = gql`
  fragment comm on Commercial {
    id
    code
    isBlocked
    receivePayment
    responsible {
      firstName
      lastName
      email
      tel
      birthDate
      address
    }
    entreprise {
      name
      email
      address
      city
      zipCode
      webSite
      description
      tva
      country
      iban
      siret
      siren
    }
  }
`;
export const useMutationCommercial = () => {
  const [addCommercial] = useMutation(ADD_COMMERCIAL_MUTATION);
  const [updateCommercialMutation] = useMutation(UPDATE_COMMERCIAL_MUTATION);
  const [blockCommercial] = useMutation(BLOCK_USER);
  function addNewCommercial({ input }) {
    return addCommercial({
      variables: {
        input,
      },
      update: (cache, { data }) => {
        // const old = cache.readFragment({
        //   fragment: FragmentCommercials,
        //   id: "CommandElasticStore",
        // });
        // cache.writeFragment({
        //   fragment: FragmentCommercials,
        //   id: "CommandElasticCommercials",
        // });
      },
    });
  }
  function updateCommercial({ id, input }) {
    return updateCommercialMutation({
      variables: {
        id,
        input,
      },
    });
  }
  function blockCommercials(id) {
    return blockCommercial({
      variables: {
        id,
      },
      update: (cache, {}) => {
        const oldData = cache.readFragment({
          fragment: FragmentCommercials,
          id,
        });
        cache.writeFragment({
          id,
          fragment: FragmentCommercials,
          data: {
            ...oldData,
            isBlocked: !oldData?.isBlocked,
          },
        });
      },
    });
  }
  return { addNewCommercial, updateCommercial, blockCommercials };
};

export const useQueryCommercials = (id) => {
  const { data, ...others } = useQuery(GET_COMMERCIALS_MUTATION);
  return {
    data: data?.commercials,
    ...others,
  };
};
export const useQueryCommercial = (id) => {
  const { data, ...others } = useQuery(GET_COMMERCIAL_QUERRY, {
    variables: {
      id,
    },
  });
  return {
    data: data?.commercial,
    ...others,
  };
};
