import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Home } from 'react-feather';
import { NavLink, useHistory } from 'react-router-dom';
import { UilAngleLeft } from '@iconscout/react-unicons';

const BreadCrumbs = (props) => {
  const history = useHistory();
  function goBack() {
    history.goBack();
  }
  return (
    <div className="content-header row">
      <div className="content-header-left col-md-12 col-12 mb-1 d-flex justify-content-between align-items-center NoFlex2">
        <div className="row breadcrumbs-top">
          <div className="col-12 d-flex align-items-center">
            <div className="breadcrumb-wrapper vx-breadcrumbs d-sm-block   p-0 border-left-grey Mb50">
              <Breadcrumb
                tag="ol"
                className="m-0 p-0 d-flex align-items-center BBLink"
              >
                {props.goBack && (
                  <div className="p-0 m-0">
                    <div
                      onClick={goBack}
                      style={{
                        backgroundColor: '#d8d8d8',
                        padding: '2px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                      }}
                    >
                      <UilAngleLeft size={20} color="gray" />
                    </div>
                  </div>
                )}
                <BreadcrumbItem tag="li" className="p-0 m-0">
                  <NavLink to="/">
                    <Home className="align-top ml-1" size={15} />
                  </NavLink>
                </BreadcrumbItem>

                {props.breadCrumbParent && (
                  <BreadcrumbItem tag="li" className="text-primary">
                    {props.breadCrumbParent}
                  </BreadcrumbItem>
                )}

                {props.breadCrumbParent2 ? (
                  <BreadcrumbItem tag="li" className="text-primary">
                    {props.breadCrumbParent2}
                  </BreadcrumbItem>
                ) : (
                  ''
                )}
                {props.breadCrumbParent3 ? (
                  <BreadcrumbItem tag="li" className="text-primary">
                    {props.breadCrumbParent3}
                  </BreadcrumbItem>
                ) : (
                  ''
                )}
                <BreadcrumbItem tag="li" active>
                  {props.breadCrumbActive}
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </div>
        </div>
        {props?.rightComponent && props.rightComponent}
      </div>
    </div>
  );
};
export default BreadCrumbs;
