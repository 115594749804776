import mock from '../mock';

export const searchResult = [
  {
    groupTitle: 'Pages',
    searchLimit: 4,
    data: [
      {
        id: 1,
        target: 'home',
        title: 'Home',
        link: '/',
        icon: 'Home',
      },
      {
        id: 2,
        target: 'page',
        title: 'Commandes',
        link: '/commandes',
        icon: 'File',
      },
      {
        id: 3,
        target: 'page',
        title: 'Produits',
        link: '/pruductList',
        icon: 'File',
      },
      {
        id: 4,
        target: 'page',
        title: 'Categories',
        link: '/categories',
        icon: 'File',
      },
      {
        id: 5,
        target: 'page',
        title: 'Supplement',
        link: '/addons',
        icon: 'File',
      },
      {
        id: 6,
        target: 'page',
        title: 'Promotions',
        link: '/discount',
        icon: 'File',
      },
      {
        id: 5,
        target: 'page',
        title: 'Point de vente',
        link: '/pros',
        icon: 'File',
      },
      {
        id: 5,
        target: 'page',
        title: 'Parametres',
        link: '/settings',
        icon: 'File',
      },
      {
        id: 5,
        target: 'page',
        title: 'Clients',
        link: '/clients',
        icon: 'File',
      },
    ],
  },,
];

mock.onGet('/api/main-search/data').reply(200, {
  searchResult,
});
