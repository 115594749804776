import gql from 'graphql-tag';
import {
  ADD_STRUCTURE_MUTATION,
  UPDATE_STRUCTURE_MUTATION,
  GET_STRUCTURES_MUTATION,
  GET_STRUCTURE_QUERRY,
} from 'graphql/tags';
import { useMutation, useQuery } from 'react-apollo';

const FragmentStructures = gql`
  fragment nodes on Structure {
    nodes {
      id
      receivePayment
      responsible {
        firstName
        lastName
        email
        tel
        birthDate
        address
      }
      entreprise {
        name
        email
        address
        city
        zipCode
        webSite
        description
        tva
        country
        iban
        siret
        siren
      }
    }
  }
`;
export const useMutationStructure = () => {
  const [addStructure] = useMutation(ADD_STRUCTURE_MUTATION);
  const [updateStructureMutation] = useMutation(UPDATE_STRUCTURE_MUTATION);
  function addNewStructure({ input }) {
    return addStructure({
      variables: {
        input,
      },
      update: (cache, { data }) => {
        cache.writeFragment({
          fragment: FragmentStructures,
          id: 'ElasticStructures',
        });
      },
    });
  }
  function updateStructure({ id, input }) {
    return updateStructureMutation({
      variables: {
        id,
        input,
      },
    });
  }
  return { addNewStructure, updateStructure };
};

export const useQueryStructures = (id) => {
  const { data, ...others } = useQuery(GET_STRUCTURES_MUTATION);

  return {
    data: data?.structures.nodes,
    ...others,
  };
};
export const useQueryStructure = (id) => {
  const { data, ...others } = useQuery(GET_STRUCTURE_QUERRY, {
    variables: {
      id,
    },
  });
  return {
    data: data?.structure,
    ...others,
  };
};
