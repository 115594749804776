import gql from 'graphql-tag';

export const GET_EMAIL_TEMPLATES = gql`
  query {
    getTemplatesEmail {
      id
      name
      templateName
    }
  }
`;

export const GET_EMAIL_TEMPLATE = gql`
  query getTemplateEmail($id: ID!) {
    getTemplateEmail(id: $id) {
      id
      name
      templateName
      defaultContent
      defaultSubject
      defaultSenderMail
      translations {
        country
        subject
        content
        description
        senderMail
        senderName
      }
    }
  }
`;
export const UPDATE_TEMPLATE_MAIL = gql`
  mutation updateMailTemplate($id: ID, $input: MailInputUpdate) {
    updateMailTemplate(id: $id, input: $input) {
      id
    }
  }
`;
