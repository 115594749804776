import gql from 'graphql-tag';

export const ADD_CATEGORY_MUTATION = gql`
  mutation addCategory($input: CategoryInput) {
    addCategory(input: $input) {
      id
      name
      photoUrl
      status
    }
  }
`;

export const ADD_SUB_CATEGORY_MUTATION = gql`
  mutation addSubCategory($input: SubCategoryInput) {
    addSubCategory(input: $input) {
      id
      name
      category {
        id
        name
      }
      status
    }
  }
`;
export const ADD_SUB_SUB_CATEGORY_MUTATION = gql`
  mutation addSubSubCategory($input: SubSubCategoryInput) {
    addSubSubCategory(input: $input) {
      id
      name
      category {
        id
        name
      }
      subCategory {
        id
        name
      }
      status
    }
  }
`;
export const UPDATE_CATEGORY_MUTATION = gql`
  mutation updateCategory($input: CategoryInput) {
    updateCategory(input: $input) {
      id
      name
      photoUrl
      status
    }
  }
`;
export const UPDATE_SUB_CATEGORY_MUTATION = gql`
  mutation updateSubCategory($input: SubCategoryInput) {
    updateSubCategory(input: $input) {
      id
      name
      category {
        id
        name
      }
      status
    }
  }
`;
export const UPDATE_SUB_SUB_CATEGORY_MUTATION = gql`
  mutation updateSubSubCategory($input: SubSubCategoryInput) {
    updateSubSubCategory(input: $input) {
      id
      name
      category {
        id
        name
      }
      subCategory {
        id
        name
      }
      status
    }
  }
`;
export const GET_ALL_CATEGORY = gql`
  query getCategories($filter: CategoryFilter) {
    getCategories(filter: $filter) {
      id
      name
      status
      photoUrl
      rank
      hasChilds
      role
      productsCount
    }
  }
`;
export const GET_ALL_CATEGORY_SUB_CATEGORY_SUB_SUB_CATEGORY = gql`
  query getCategories($filter: CategoryFilter) {
    getCategories(filter: $filter) {
      id
      name
      status
      photoUrl
      rank
      hasChilds
      role
      description
      productsCount
      subCategories {
        id
        name
        status
        description
        photoUrl
        subSubCategories {
          id
          name
          status
          photoUrl
          description
        }
      }
    }
  }
`;
export const GET_GATEGORY_BY_ID = gql`
  query getCategory($id: String) {
    getCategory(id: $id) {
      id
      name
      status
      photoUrl
      rank
    }
  }
`;

export const GET_ALL_SUB_CATEGORY_FOR_A_CATEGORY = gql`
  query getSubCategories($filter: SubCategoryFilter) {
    getSubCategories(filter: $filter) {
      id
      name
      status
      photoUrl
      rank
      hasChilds
      category {
        id
        name
        photoUrl
        rank
      }
    }
  }
`;

export const GET_ALL_SUB_SUB_CATEGORY_FOR_A_SUB_CATEGORY = gql`
  query getSubSubCategories($filter: SubSubCategoryFilter) {
    getSubSubCategories(filter: $filter) {
      id
      name
      status
      photoUrl
      category {
        id
        name
        photoUrl
        rank
      }
      subCategory {
        id
        name
        photoUrl
        rank
      }
    }
  }
`;

export const GET_ALL_CATEGORY_WITH_SUB_CATEGORIES = gql`
  query getCategories($filter: CategoryFilter) {
    getCategories(filter: $filter) {
      id
      name
      status
      photoUrl
      rank
      role
      subCategories {
        id
        name
        status
        photoUrl
        rank
        role
      }
    }
  }
`;
export const GET_ALL_SUB_CATEGORY = gql`
  query getSubCategories($filter: SubCategoryFilter) {
    getSubCategories(filter: $filter) {
      id
      name
      status
      photoUrl
      rank
      hasChilds
      category {
        id
        name
        photoUrl
        rank
      }
    }
  }
`;
export const DELETE_CATEGORY_MUTATION = gql`
  mutation deleteCategory(
    $id: ID
    $deleteChild: Boolean
    $deleteProducts: Boolean
  ) {
    deleteCategory(
      id: $id
      deleteChild: $deleteChild
      deleteProducts: $deleteProducts
    ) {
      id
      name
      photoUrl
    }
  }
`;
export const DELETE_SUB_SUB_CATEGORY_MUTATION = gql`
  mutation deleteSubSubCategory($id: ID) {
    deleteSubSubCategory(id: $id) {
      id
      name
      photoUrl
    }
  }
`;
export const DELETE_SUB_CATEGORY_MUTATION = gql`
  mutation deleteSubCategory($id: ID) {
    deleteSubCategory(id: $id) {
      id
    }
  }
`;
export const ENABLE_CATEGORY_MUTATION = gql`
  mutation enableCategory($id: ID!) {
    enableCategory(id: $id) {
      id
    }
  }
`;
export const ENABLE_SUB_CATEGORY_MUTATION = gql`
  mutation enableSubCategory($id: ID!) {
    enableSubCategory(id: $id) {
      id
    }
  }
`;

export const ENABLE_SUB_SUB_CATEGORY_MUTATION = gql`
  mutation enableSubSubCategory($id: ID!) {
    enableSubSubCategory(id: $id) {
      id
    }
  }
`;

export const REORDER_CATEGORY = gql`
  mutation reorderCategories(
    $source: ID
    $destination: ID
    $rankSrc: Int
    $rankDst: Int
  ) {
    reorderCategories(
      source: $source
      destination: $destination
      rankSrc: $rankSrc
      rankDst: $rankDst
    ) {
      id
    }
  }
`;
export const SORT_CATEGORY = gql`
  mutation sortCategories($input: [SortInput]) {
    sortCategories(input: $input) {
      id
    }
  }
`;
export const SORT_SUB_CATEGORY = gql`
  mutation sortSubCategories($input: [SortInput]) {
    sortSubCategories(input: $input) {
      id
    }
  }
`;

export const REORDER_SUB_CATEGORY = gql`
  mutation reorderSubCategories(
    $source: ID
    $destination: ID
    $rankSrc: Int
    $rankDst: Int
  ) {
    reorderSubCategories(
      source: $source
      destination: $destination
      rankSrc: $rankSrc
      rankDst: $rankDst
    ) {
      id
    }
  }
`;
