import React from "react";
import ScrollToTop from "react-scroll-up";
import { ModalBody, ModalFooter, Button, Spinner, Row, Col } from "reactstrap";
import { ArrowUp } from "react-feather";
import classnames from "classnames";
import { useTranslate } from "utility/context/Language";
import ModalCentered from "components/reactstrap/modal/ModalCentered";
import { useQueryMentionLegalsApp } from "views/faq/hooks/faq.hooks";
import config from "configs/config";
const Footer = (props) => {
  const { data, loading } = useQueryMentionLegalsApp();

  const footerTypeArr = ["sticky", "static", "hidden"];
  const { labels, settings } = useTranslate();
  const [modal, setmodal] = React.useState(false);

  function getHostName() {
    const host = window.location.hostname;
    const [subdomaine, domaine, com] = host?.split(".");
    return `https://${domaine}.${com}`;
  }
  return (
    <React.Fragment>
      <footer
        className={classnames("footer footer-light", {
          "footer-static":
            props.footerType === "static" ||
            !footerTypeArr.includes(props.footerType),
          "d-none": props.footerType === "hidden",
        })}
      >
        <p className="mb-0 clearfix">
          <span className="float-md-left d-block d-md-inline-block mt-25">
            {labels?.footer?.copyRight}
            <a
              onClick={() => setmodal(true)}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-color"
            >
              {labels?.footer?.generalCondition}
            </a>
            de
            <a
              href={getHostName()}
              className="primary-color"
              target="_blank"
              rel="noopener noreferrer"
            >
              {settings?.appName}
            </a>
            <span>{`v ${config?.DASHBOARD_VERSION}`}</span>
          </span>
        </p>
        {props.hideScrollToTop === false ? (
          <ScrollToTop showUnder={160}>
            <Button color="primary" className="btn-icon scroll-top">
              <ArrowUp size={15} />
            </Button>
          </ScrollToTop>
        ) : null}
      </footer>
      <ModalCentered
        modal={modal}
        className="modal-lg"
        modalHeaderTitle={labels?.modalTitleMentionLegal}
        toggleModalProps={() => setmodal(!modal)}
      >
        <ModalBody style={{ height: "600px", overflow: "auto" }}>
          {data?.nodes?.map((mention) => (
            <>
              <div className="pt-2 pb-2">
                <span className="font-medium-3 black">{mention?.title}</span>
              </div>

              <div dangerouslySetInnerHTML={{ __html: mention?.content }} />
            </>
          ))}
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalCentered>
    </React.Fragment>
  );
};

export default Footer;
