import gql from "graphql-tag";

export const LOAD_FAQ = gql`
  query getFAQS($ref: FAQEnum) {
    getFAQS(ref: $ref) {
      nodes {
        id
        title
        content
      }
    }
  }
`;
export const CONTACT_MUTATION = gql`
  mutation sendSupportMail($input: MailSupportInput) {
    sendSupportMail(input: $input)
  }
`;
