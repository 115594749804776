import gql from 'graphql-tag';

export const ADD_COMMERCIAL_MUTATION = gql`
  mutation addCommercial($input: CommercialInput) {
    addCommercial(input: $input) {
      id
      code
      responsible {
        lastName
      }
    }
  }
`;
export const UPDATE_COMMERCIAL_MUTATION = gql`
  mutation updateCommercial($id: String, $input: CommercialInput) {
    updateCommercial(id: $id, input: $input) {
      id
      responsible {
        firstName
        lastName
      }
    }
  }
`;
export const BLOCK_USER = gql`
  mutation blockCommercial($id: ID) {
    blockCommercial(id: $id)
  }
`;
export const GET_COMMERCIALS_MUTATION = gql`
  query commercials($skip: Int, $limit: Int) {
    commercials(limit: $limit, skip: $skip) {
      nodes {
        id
        code
        receivePayment
        responsible {
          firstName
          lastName
          email
          tel
          birthDate
          address
        }
        entreprise {
          name
          email
          address
          city
          zipCode
          webSite
          description
          tva
          country
          iban
          siret
          siren
        }
      }
    }
  }
`;

export const GET_COMMERCIAL_QUERRY = gql`
  query commercial($id: String) {
    commercial(id: $id) {
      id
      code
      receivePayment
      structure
      responsible {
        firstName
        lastName
        email
        city
        tel
        birthDate
        address
        nidFront
        nidBack
        verifyHomeAddress
        zipCode
      }
      entreprise {
        name
        email
        tel
        address
        city
        zipCode
        webSite
        description
        tva
        country
        iban
        siret
        siren
      }
    }
  }
`;

export const ADD_STRUCTURE_MUTATION = gql`
  mutation addStructure($input: StructureInput) {
    addStructure(input: $input) {
      id
      responsible {
        lastName
      }
      entreprise {
        name
      }
    }
  }
`;
export const UPDATE_STRUCTURE_MUTATION = gql`
  mutation updateStructure($id: String, $input: StructureInput) {
    updateStructure(id: $id, input: $input) {
      id
      responsible {
        firstName
        lastName
      }
    }
  }
`;
export const GET_STRUCTURES_MUTATION = gql`
  query structures($skip: Int, $limit: Int) {
    structures(limit: $limit, skip: $skip) {
      nodes {
        id
        receivePayment
        responsible {
          firstName
          lastName
          email
          tel
          birthDate
          address
        }
        entreprise {
          name
          email
          address
          city
          zipCode
          webSite
          description
          tva
          country
          iban
          siret
          siren
        }
      }
    }
  }
`;
export const GET_STRUCTURE_QUERRY = gql`
  query structure($id: String) {
    structure(id: $id) {
      id
      receivePayment
      stripeReady
      stripeRequiredField
      stripeExist
      responsible {
        firstName
        lastName
        email
        city
        tel
        birthDate
        address
        nidFront
        nidBack
        verifyHomeAddress
        zipCode
      }
      entreprise {
        name
        email
        tel
        address
        city
        zipCode
        webSite
        description
        tva
        country
        iban
        siret
        siren
      }
    }
  }
`;
