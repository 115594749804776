import gql from "graphql-tag";

const CommandFragment = {
  command: gql`
    fragment CommandFragment on Command {
      id
      num
      createdAt
      state
      client {
        firstName
        lastName
      }
      amount
      state
      deliveryMode
      delivrable
    }
  `,
};
export const GET_COMMANDS_FLORIST = gql`
  query getCommandsFlorist($filter: CommandFilter, $skip: Int, $limit: Int) {
    getCommandsFlorist(filter: $filter, skip: $skip, limit: $limit) {
      nodes {
        ...CommandFragment
      }
      count
    }
  }
  ${CommandFragment.command}
`;

export const GET_COMMAND_BY_NUM = gql`
  query getCommandByNum($num: Int) {
    getCommandByNum(num: $num) {
      id
      oldDeliveryTime
      florist {
        id
        name
      }
      delivrable
      status {
        state
        date
      }
      items {
        variant {
          id
          product
          stockManaged
          refVariation
          stockValue
          price
          weight
          values {
            label
            value
          }
        }
        product {
          id
          name
          photoUrls {
            photoUrl
          }
          description
          minPrice
        }
        quantity
        promotion {
          id
        }
      }
      client {
        id
        firstName
        lastName
        tel
        fullName
      }
      deliveryAddress {
        firstName
        lastName
        address
        deliveryMode
        descrption
      }
      num
      createdAt
      totalPrice
      subtotalPrice
      deliveryMode
      deliveryTime
      deliveryCancellationRaison {
        raison
        canceledDate
        canceledBy
        refunded
      }
      apiResponseLivringInfos {
        trackingUrl
        etaToDestination
        etaToOrigin
        api
        driver {
          firstName
          lastName
          phone
        }
      }
    }
  }
`;

export const GET_COMMAND_BY_ID = gql`
  query getCommandById($id: String) {
    getCommandById(id: $id) {
      florist {
        id
        name
        photoUrl
        address
        city
        storeCountry {
          name
        }
        siret
      }
      driver {
        id
        lastName
        firstName
        photoUrl
        mobile
      }
      state
      captured
      delivrable
      status {
        state
        date
      }
      timeSlot
      items {
        _id
        addons {
          addon {
            id
            name

            addon {
              id
              name
              multiQuantity
            }
            items {
              name
              price
              addon
              itemId
              outOfStock
              checked
            }
          }
          itemId
          price
          store

          quantity
        }
        variant {
          id
          product
          refVariation
          price
          weight
          values {
            label
            value
          }
        }
        product {
          id
          name
          photoUrls {
            photoUrl
          }
          enableSimpleProduct
          enableMultiVariation
          enableAddons
        }
        quantity
        promotion {
          id
          title
          value
          promotionType
        }
      }
      client {
        id
        firstName
        lastName
        tel
        fullName
        profile {
          email
        }
        photoUrl
      }
      deliveryAddress {
        firstName
        lastName
        address
        deliveryMode
        address1
        phone
        descrption
      }
      num
      id
      createdAt
      totalPrice
      subtotalPrice
      deliveryMode
      paymentMethods
      deliveryTime
      oldDeliveryTime
      deliveredBy
      paymentMode
      paymentState
      deliveryFee
      feeService
      totalTax
      message
      deliveryCancellationRaison {
        raison
        canceledDate
        canceledBy
        refunded
      }
      apiResponseLivringInfos {
        trackingUrl
        etaToDestination
        etaToOrigin
        api
        driver {
          firstName
          lastName
          phone
        }
      }
      delivery {
        paymentName
        mode
        api {
          photoUrl
        }
      }
      canceledBy {
        role
        name
        canceledDate
      }
    }
  }
`;
export const UPDATE_COMMAND = gql`
  mutation updateCommand($id: ID, $input: CommandInputState) {
    updateCommand(id: $id, input: $input) {
      id
    }
  }
`;
export const EXPORT_COMMANDS = gql`
  mutation exportCommands($query: Any) {
    exportCommands(query: $query)
  }
`;
export const REMBOURCE_ORDER = gql`
  mutation refoundCustomerAmount($order: String) {
    refoundCustomerAmount(order: $order)
  }
`;
export const RE_FIND_DRIVER = gql`
  mutation reFindDriver($id: ID) {
    reFindDriver(id: $id) {
      id
    }
  }
`;
export const CHANGE_STATE_COMMAND = gql`
  mutation changeStateCommand(
    $id: ID
    $state: String
    $transportType: String
    $deliveryChoice: String
  ) {
    changeStateCommand(
      id: $id
      state: $state
      transportType: $transportType
      deliveryChoice: $deliveryChoice
    ) {
      id
    }
  }
`;
export const UPDATE_COMMAND_DELIVRY_TIME = gql`
  mutation updateCommandDeliveryTime($id: ID, $delayTime: Int) {
    updateCommandDeliveryTime(id: $id, delayTime: $delayTime) {
      id
    }
  }
`;
