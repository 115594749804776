import React from "react";
import classnames from "classnames";
import themeConfig from "../configs/themeConfig";

const FullPageLayout = ({ children, ...rest }) => {
  const isInscriptionPagePublicPath = ["/AddCommercialS", "/AddPros"]?.includes(
    rest?.location?.pathname
  );

  return (
    <div
      className={classnames("full-layout wrapper blank-page dark-layout", {
        "layout-dark": themeConfig.layoutDark,
      })}
    >
      <div className="app-content">
        <div className="content-wrapper">
          <div className="content-body">
            <div
              className={classnames(
                "flexbox-container",
                isInscriptionPagePublicPath && "full-height"
              )}
            >
              <main className="main w-100">{children}</main>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullPageLayout;
