import gql from 'graphql-tag';

export const ADD_THEME_MUTATION = gql`
	mutation addTheme($input: ThemeInput) {
		addTheme(input: $input) {
			id
			name
			photoUrl
			status
           
		}
	}
`;
export const UPDATE_THEME_MUTATION = gql`
	mutation updateTheme($input: ThemeInput) {
		updateTheme(input: $input) {
			id
			name
			photoUrl
			status
           
		}
	}
`;

export const GET_ALL_THEME = gql`
	query {
		getThemes {
			id
			name
			photoUrl
			status
		}
	}
`;
export const DELETE_THEME_MUTATION = gql`
mutation deleteTheme($id: ID) {
    deleteTheme(id: $id) {
        id
    
    }
}
`;
export const ENABLE_THEME_MUTATION = gql`
mutation enableTheme($id: ID!) {
    enableTheme(id: $id){
		id
	}
}
`;
