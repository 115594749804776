import { useQuery, useMutation } from "react-apollo";
import { LOAD_FAQ, CONTACT_MUTATION } from "graphql/tags";

export const useQueryLoadFAQS = (ref) => {
  const { data, loading, error, refetch } = useQuery(LOAD_FAQ, {
    variables: { ref: "PRO" },
  });
  return {
    getFAQS: data?.getFAQS,
    loading,
    error,
    refetch,
  };
};
export const useMutationContact = () => {
  const [sendSupportMail] = useMutation(CONTACT_MUTATION);
  function contact(input) {
    return sendSupportMail({
      variables: {
        input,
      },
    });
  }
  return { contact };
};
