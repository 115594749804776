import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class ModalCentered extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: props.modal,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.modal !== this.state.modal) {
      this.setState({ modal: nextProps.modal });
    }
  }

  render() {
    const { modalHeaderTitle } = this.props;
    return (
      <Modal
        isOpen={this.state.modal}
        toggle={this.props.toggleModalProps}
        {...this.props}
        className="modal-dialog-centered max-width-96"
      >
        <ModalHeader toggle={this.props.toggleModalProps}>
          <span className="black"> {modalHeaderTitle}</span>
        </ModalHeader>
        <ModalBody>{this.props.children}</ModalBody>
        {this.props?.showFooter && (
          <ModalFooter>
            <Button color="primary" onClick={this.props.toggleModalProps}>
              Accept
            </Button>
          </ModalFooter>
        )}
      </Modal>
    );
  }
}
export default ModalCentered;
